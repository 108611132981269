import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import './App.css';
import ClipLoader from 'react-spinners/ClipLoader';

function App() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productInfo, setProductInfo] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const productsPerPage = 6;
  const maxRetries = 3;

  // Referências para os botões de navegação de produtos
  const prevProductRef = useRef(null);
  const nextProductRef = useRef(null);

  useEffect(() => {
    retryLoadProducts();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      filterProducts();
      setLoading(false); // Finaliza o loader quando os produtos forem carregados
    }
  }, [products]);

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  function retryLoadProducts(retries = 0) {
    fetch('https://playlinky.com/backend3.php?action=getProducts')
      .then(response => response.json())
      .then(products => {
        setProducts(products);
        setLoading(false); // Certifique-se de que o loading é atualizado aqui
      })
      .catch(error => {
        if (retries < maxRetries) {
          retryLoadProducts(retries + 1);
        } else {
          setError('Falha ao carregar produtos. Por favor, tente novamente mais tarde.');
          setLoading(false); // Certifique-se de que o loading é atualizado aqui
        }
      });
  }

  function filterProducts() {
    const filtered = products.filter(product => product.categoria_id === 1); // Supondo que a categoria "Sites" tenha ID 1
    setFilteredProducts(filtered);
  }

  function loadProductInfo(productId) {
    setLoading(true);
    fetch(`https://playlinky.com/backend3.php?action=getProductDescription&product_id=${productId}`)
      .then(response => response.json())
      .then(info => {
        setProductInfo(info);
        setModalVisible(true);
        setLoading(false);
      })
      .catch(error => {
        setError('Falha ao carregar informações do produto. Por favor, tente novamente.');
        setLoading(false);
      });
  }

  function sendToWhatsApp(product) {
    const confirmBuy = window.confirm('Você será redirecionado para nosso WhatsApp');
    if (confirmBuy) {
      const productName = encodeURIComponent(product.nome);
      const productCode = encodeURIComponent(product.codigo);
      const whatsappLink = `https://wa.me/5511969024263?text=Olá,%20Gostaria%20de%20adquirir%20o%20modelo%3A%0A${productName}%20cod:%20${productCode}`;
      window.open(whatsappLink, '_blank');
    }
  }

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = Math.min(startIndex + productsPerPage, filteredProducts.length);
  const currentProducts = filteredProducts.slice(startIndex, endIndex);

  const isMobile = window.innerWidth <= 768;

  return (
    <div className="app-container">
      {loading ? (
        <div className="loader-container">
          <ClipLoader color={"#123abc"} loading={loading} size={150} />
        </div>
      ) : error ? (
        <div className="error-container">
          <p>{error}</p>
          <button onClick={() => {
            setLoading(true);
            setError(null);
            retryLoadProducts();
          }}>
            Tentar Novamente
          </button>
        </div>
      ) : (
        isMobile ? (
          <Swiper 
            spaceBetween={20} 
            slidesPerView={1.2} 
            centeredSlides
            modules={[Navigation]}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevProductRef.current;
              swiper.params.navigation.nextEl = nextProductRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
          >
            {currentProducts.map(product => (
              <SwiperSlide key={product.codigo}>
                <div className="product">
                  <a href={product.link} target="_blank" rel="noopener noreferrer" className="ver-button">
                    Ver Demonstração
                  </a>
                  <img 
                    src={`https://playlinky.com/produtos/${product.imagem}`} 
                    alt="Imagem do Produto" 
                    onClick={() => loadProductInfo(product.id)} 
                  />
                  <div className="product-info">
                    <p className="product-title">{product.nome}</p>
                    <button className="comprar-button" onClick={() => sendToWhatsApp(product)}>
                      Comprar
                    </button>
                    <p className="product-price">R$ {product.preco}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div ref={prevProductRef} className="swiper-button-prev"></div>
            <div ref={nextProductRef} className="swiper-button-next"></div>
          </Swiper>
        ) : (
          <div className="product-container">
            {currentProducts.map(product => (
              <div key={product.codigo} className="product">
                <a href={product.link} target="_blank" rel="noopener noreferrer" className="ver-button">
                  Ver Demonstração
                </a>
                <img 
                  src={`https://playlinky.com/produtos/${product.imagem}`} 
                  alt="Imagem do Produto" 
                  onClick={() => loadProductInfo(product.id)} 
                />
                <div className="product-info">
                  <p className="product-title">{product.nome}</p>
                  <button className="comprar-button" onClick={() => sendToWhatsApp(product)}>
                    Comprar
                  </button>
                  <p className="product-price">R$ {product.preco}</p>
                </div>
              </div>
            ))}
          </div>
        )
      )}

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
          <a
            key={pageNumber}
            href="#"
            className={pageNumber === currentPage ? 'active' : ''}
            onClick={(e) => {
              e.preventDefault();
              setCurrentPage(pageNumber);
            }}
          >
            {pageNumber}
          </a>
        ))}
      </div>

      {modalVisible && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setModalVisible(false)}>&times;</span>
            <h3>Info</h3>
            {productInfo ? (
              <>
                <p>{productInfo.descricao}</p>
                <a href={productInfo.link} target="_blank" rel="noopener noreferrer" className="ver-button">
                  Demo
                </a>
              </>
            ) : (
              <p>Informações do produto não disponíveis.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;